import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default () => (
  <Layout>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <div>Sorry, the page you are looking for could not be found.</div>
  </Layout>
);
